import Style from "../../assets/styles/header.module.css";
import {useEffect, useState} from "react";
import Logo from "../../assets/images/logo_white.svg";
import {useNavigate, useParams} from "react-router-dom";
import Search from "../../components/search";
import Loading from "../../components/loading";
import {useDispatch, useSelector} from "react-redux";
import {setLoading} from "../../redux/actions/user";
import {getCategoryApi, getSubCategoryApi, getSubSubCategoryApi} from "../../api/api";
import {urlDasher} from "../../api/helper";
import {Menu, Spin} from "antd";
import { LoadingOutlined } from '@ant-design/icons';

const Header = () => {

    const Navigate = useNavigate();
    const dispatch = useDispatch();
    const Params = useParams();
    const {province, category, subcat, subsubcat} = Params;
    const [Nav, setNav] = useState(false);
    const loading = useSelector((state)=>state?.user?.loading);
    const selectedProvince = useSelector((state)=>state?.user?.province) || "ایران";
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [subSubCategories, setSubSubCategories] = useState([]);
    const [menuStatus, setMenuStatus] = useState("c");
    const [loadingMenu, setLoadingMenu] = useState(false);

    useEffect(() => {
        dispatch(setLoading(true))

        setTimeout(() => {
            dispatch(setLoading(false))
        }, 2000);

    }, [Params]);

    useEffect(()=>{
        getCategory();
    },[province]);

    const handleMenu = (x, t, sc, ssc) => {

        if(t === "c"){
            Navigate(`/browse/${selectedProvince}/${urlDasher(x)}`);
        } else if(t === "sc"){
            Navigate(`/browse/${selectedProvince}/${urlDasher(sc)}/${urlDasher(x)}`);
        } else if(t === "ssc"){
            Navigate(`/browse/${selectedProvince}/${urlDasher(sc)}/${urlDasher(ssc)}/${urlDasher(x)}`);
        }

        setNav(false)
    }

    const getCategory = async () => {
        setLoadingMenu(true);
        await getCategoryApi().then((res)=>{
            res.data.filter((x)=>(
         x.label = <div className="d-flex align-items-center justify-content-between">
             <span onClick={()=> handleMenu(x.name, 'c')}>{x.name}</span>
             <span onClick={()=> getSubCat(x.id, x.name)}>
                 <i className="las la-angle-left"></i>
             </span>
         </div>,
             x.value = x.id,
             x.key = `c_${x.id}`,
             x.icon = <i style={{fontSize: "20px"}} className="icon" data-cat-id={x.id}></i>))
            setCategories(res.data)
            setLoadingMenu(false)
        }).catch((err)=>{
            console.log(err)
        })
    }

    const getSubCat = async (id, name) => {
        setLoadingMenu(true);
        await getSubCategoryApi(id).then((res)=>{
            res.data.filter((x)=>(
                x.label = <div className="d-flex align-items-center justify-content-between">
                    <span onClick={()=> handleMenu(x.name, 'sc', name)}>{x.name}</span>
                    <span onClick={()=> getSubSubCat(x.id, name, x.name)}>
                        <i className="las la-angle-left"></i>
                    </span>
                </div>,
                    x.value = x.id,
                    x.key = `sc_${x.id}`,
                    x.icon = <i style={{fontSize: "20px"}} className="icon" data-sub-cat-id={x.id}></i>))

            setSubCategories(res.data)
            setMenuStatus("sc")
            setLoadingMenu(false)
        }).catch((err)=>{
            console.log(err)
        })
    }


    const getSubSubCat = async (id, sc, name) => {
        setLoadingMenu(true);
        await getSubSubCategoryApi(id).then((res)=>{

            if(res.data.length){
                res.data.filter((x)=>(
                    x.label = <div className="d-flex align-items-center justify-content-between">
                        <span onClick={()=> handleMenu(x.name, 'ssc', sc, name)}>{x.name}</span>
                    </div>,
                        x.value = x.id,
                        x.key = `ssc_${x.id}`,
                        x.icon = <i style={{fontSize: "20px"}} className="icon" data-subsub-cat-id={x.id}></i>))

                setSubSubCategories(res.data)
                setMenuStatus("ssc")
            }

            setLoadingMenu(false)

        }).catch((err)=>{
            console.log(err)
        })
    }

    return (
        <>
            <header className={Style.HomeHeader}>
                <div className="d-flex justify-content-between align-items-center">
                    <div className={Style.HeaderLogo}>
                        <img width={50} height={26} src={Logo}/>
                        <span className={Style.DefaultProvince}>{selectedProvince}</span>
                    </div>
                    <div
                        className={`${Nav ? `${Style.HeaderNav} ${Style.HeaderNavActive}` : `${Style.HeaderNav}`}`}
                        onClick={()=>setNav(!Nav)}
                    >
                        <span></span>
                    </div>
                </div>
                <div className={Style.HeaderSearch}>
                    <Search/>
                </div>
                <div className={`${Nav ? `${Style.HeaderMenu} ${Style.HeaderMenuOpen}` : Style.HeaderMenu}`}>
                    <div className={Style.SidebarHeader}>
                        <img width={50} height={26} src={Logo}/>

                        {menuStatus != "c" && !loadingMenu ?
                        <div className="d-flex align-items-center"
                             onClick={()=> menuStatus === "sc" ? setMenuStatus("c")
                                 : menuStatus === "ssc" ? setMenuStatus("sc")
                                     : null}>
                            <span style={{marginLeft: "5px", fontSize: "14px", color: "white"}}>بازگشت</span>
                            <i style={{fontSize: "18px", color: "white"}} className="las la-reply"></i>
                        </div>
                            : null}
                        {loadingMenu ?
                            <Spin
                                indicator={
                                    <LoadingOutlined
                                        style={{
                                            fontSize: 24,
                                            color: "white"
                                        }}
                                        spin
                                    />
                                }
                            /> : null}
                    </div>

                    {menuStatus === "c" && categories?.length ?
                        <Menu
                            mode="inline"
                            items={categories}
                        /> :
                    menuStatus === "sc" && subCategories?.length ?
                        <Menu
                            mode="inline"
                            items={subCategories}
                        /> :
                        menuStatus === "ssc" && subSubCategories?.length ?
                            <Menu
                                mode="inline"
                                items={subSubCategories}
                            /> :
                        null}
                </div>
            </header>
            <Loading status={loading}/>
        </>
    )
}

export default Header;