import Style from "../../assets/styles/footer.module.css";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {getProvinceApi} from "../../api/api";
import {useDispatch, useSelector} from "react-redux";
import {setDefaultProvince, setLoading} from "../../redux/actions/user";
import {urlDasher} from "../../api/helper";

const Footer = () => {

    const dispatch = useDispatch();
    const [provSelector, setProvSelector] = useState(false);
    const [provinces, setProvinces] = useState([]);
    const province = useSelector((state)=>state?.user?.province) || "ایران";

    useEffect(()=>{
        getProvince();
    },[])

    const getProvince = async () => {
        await getProvinceApi().then((r)=>{
            setProvinces(r.data)
        }).catch((err)=>{
            console.log(err)
        })
    }

    return (
        <>
            <footer className={Style.HomeFooter}>
                <ul className="d-flex list-unstyled align-items-center justify-content-between">
                    <li
                        onClick={()=>setProvSelector(!provSelector)}
                        className={`${provSelector ? `${Style.FooterLinkActive}` : null}`}
                    >
                        <i className="las la-map-marker"></i>
                        {provSelector ? <div className={Style.ProvSelectorClose}><i className="las la-times"></i></div> : null}
                        <span>{province}</span>
                    </li>
                    <li>
                        <i className="las la-home"></i>
                        <Link to="/">
                            <span>خانه</span>
                        </Link>
                    </li>
                    <li className={Style.FooterAddIcon}>
                        <Link target="_blank" to="https://seller.nobazaar.ir/ads/new">
                            <i className="las la-plus"></i>
                        </Link>
                    </li>
                    <li>
                        <i className="las la-bookmark"></i>
                        <Link target="_blank" to="https://seller.nobazaar.ir/ads/bookmark">
                            <span>ذخیره ها</span>
                        </Link>
                    </li>
                    <li>
                        <i className="las la-user"></i>
                        <Link target="_blank" to="https://seller.nobazaar.ir">
                            <span>پنل کاربری</span>
                        </Link>
                    </li>
                </ul>
            </footer>
            <div className={`${provSelector ? `${Style.ProvinceSelector} ${Style.ProvinceSelectorActive}` : `${Style.ProvinceSelector}`}`}>
                <ul className={`${Style.ProvinceList} d-flex list-unstyled m-0 p-0 flex-wrap`}>
                    <li onClick={()=> (dispatch(setDefaultProvince('ایران')), setProvSelector(false), setLoading(false))}>
                        <Link to={`/browse/ایران`}>
                            <i className="las la-map-marker"></i>
                            <strong>همه ایران</strong>
                        </Link>
                    </li>

                    {provinces?.map((Prov, Index)=>(
                        <li
                            key={Index}
                            className={province === Prov.title ? Style.SelectedProvince : null}
                            onClick={()=>(dispatch(setDefaultProvince(Prov.title)), setProvSelector(false), dispatch(setLoading(true)))}
                        >
                            <Link to={`/browse/${urlDasher(Prov.title)}`}>
                                <span>{Prov.title}</span>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </>
)
}

export default Footer;