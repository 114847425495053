import Style from "../assets/styles/ads.module.css";
import {Link} from "react-router-dom";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {Root} from "../api/setting";
import Placeholder from "../assets/images/placeholder.gif";
import DefaultImage from "../assets/images/default.png";
import {AdsPrice, AdsType, urlDasher} from "../api/helper";

const Ads = ({Ad}) => {

    return (
        <div span={12}>
            <div className={Style.Ads}>
                <div className={`${Style.AdsType} ${Ad?.type === 1 ? Style.AdsTypeNew : Ad?.type === 2 ? Style.AdsTypeUsed : Ad?.type === 3 ? Style.AdsTypeService : null}`}>
                    {AdsType(Ad?.type)?.label}
                </div>

                <Link to={`/view/${Ad.id}`}>
                    {Ad.img1 ?
                        <LazyLoadImage
                            src={`${Root}/seller/uploads/${Ad.user}/${Ad.adsid}/${Ad.img1}-thumb.jpg`}
                            alt={Ad.title}
                            placeholderSrc={Placeholder}
                            style={{width: "100%"}}
                        />
                        : Ad.img2 ?
                            <LazyLoadImage
                                alt={Ad.title}
                                src={`${Root}/seller/uploads/${Ad.user}/${Ad.adsid}/${Ad.img2}-thumb.jpg`}
                                placeholderSrc={Placeholder}
                                style={{width: "100%"}}
                            />
                            : Ad.img3 ?
                                <LazyLoadImage
                                    alt={Ad.title}
                                    src={`${Root}/seller/uploads/${Ad.user}/${Ad.adsid}/${Ad.img3}-thumb.jpg`}
                                    placeholderSrc={Placeholder}
                                    style={{width: "100%"}}
                                />
                                : Ad.img4 ?
                                    <LazyLoadImage
                                        alt={Ad.title}
                                        src={`${Root}/seller/uploads/${Ad.user}/${Ad.adsid}/${Ad.img4}-thumb.jpg`}
                                        placeholderSrc={Placeholder}
                                        style={{width: "100%"}}
                                    />
                                    : Ad.img5 ?
                                        <LazyLoadImage
                                            alt={Ad.title}
                                            src={`${Root}/seller/uploads/${Ad.user}/${Ad.adsid}/${Ad.img5}-thumb.jpg`}
                                            placeholderSrc={Placeholder}
                                            style={{width: "100%"}}
                                        />
                                        : <div className={Style.AdsImageDefault}><img src={DefaultImage}/></div>}
                    <h2>{Ad.title}</h2>
                </Link>

                <div className={Style.AdsPrice}>{AdsPrice(Ad.price, Ad.pricetype)}</div>
                <div className="d-flex align-items-center justify-content-between">
                    <div className={Style.AdsCat}>
                        <i className="icon" data-cat-id={Ad?.category?.id}></i>
                        <Link to={`/browse/${urlDasher(Ad?.province?.title)}/${urlDasher(Ad?.category?.name)}`}>{Ad?.category?.name}</Link>
                    </div>
                    <div className={Style.AdsLocation}>
                        <i className="la la-map-marker"></i>
                        <Link to={`/browse/${urlDasher(Ad?.province?.title)}`}>{Ad?.province?.title}</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Ads;