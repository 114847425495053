import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {getAdsApi, getCategoryApi, getSubCategoryByNameApi, getSubSubCatByNameApi} from "../../api/api";
import {useEffect, useState} from "react";
import {unDasher} from "../../api/helper";
import Ads from "../../components/ads";
import HomeLayout from "../../template/home";
import Loading from "../../components/loading";
import Style from "../../assets/styles/browse.module.css";
import {useSelector} from "react-redux";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import {Alert, Spin} from "antd";
import Filter from "../../components/filter";
import Categories from "../../components/categories";

const BrowseAds = () => {

    const Navigate = useNavigate();
    const loading = useSelector((state)=>state?.user?.loading);
    const [loader, setLoader] = useState(false);
    const Params = useParams();
    const [searchParams] = useSearchParams()
    const paramsObject = Object.fromEntries(searchParams);
    const [ads, setAds] = useState([]);
    const {province, category, subcat, subsubcat} = Params;
    const {
        c, d, t,
        p, b, m,
        ex, ml, met,
        cl, y, fac,
        s, rent, page
    } = paramsObject;
    const data = {
        ...(province && decodeURIComponent(province) != "ایران" && {province: unDasher(province)}),
        ...(c && {city: c}),
        ...(d && {district: d}),
        ...(category && {category: unDasher(category)}),
        ...(subcat && {subcat: unDasher(subcat)}),
        ...(subsubcat && {subsubcat: unDasher(subsubcat)}),
        ...(t && {type: t}),
        ...(p && {pricetype: p}),
        ...(b && {brand: b}),
        ...(m && {model: m}),
        ...(ex && {exchange: ex}),
        ...(ml && {mileage: ml}),
        ...(met && {metrage: met}),
        ...(cl && {color: cl}),
        ...(y && {year: y}),
        ...(fac && {facilities: fac}),
        ...(s && {special: s}),
        ...(rent && {rent: rent}),
        ...(page && {page: page}),
    }
    let pageTitle = subsubcat ? subsubcat : subcat ? subcat : category ? category : province ? province : null;
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [subSubCategories, setSubSubCategories] = useState([]);


    useEffect(()=>{
        getAdsApiApi();
        getCategory();

        if(subcat){
            getSubSubCat();
        }

        if (category){
            getSubCategory();
        }

    },[Params])

    const getAdsApiApi = async () => {
        setLoader(true)
        await getAdsApi(data).then((res)=>{
            setAds(res.data)
            setLoader(false)
        }).catch((err)=>{
            console.log(err)
        })
    }

    const getCategory = async () => {
        await getCategoryApi().then((res)=>{
            setCategories(res.data)
        }).catch((err)=>{
            console.log(err)
        })
    }

    const getSubCategory = async () => {
        const data = {
            category: unDasher(category)
        }
        await getSubCategoryByNameApi(data).then((res)=>{
            setSubCategories(res.data)
        }).catch((err)=>{
            console.log(err)
        })
    }

    const getSubSubCat = async () => {
        const data = {
            subcat: unDasher(subcat)
        }
        await getSubSubCatByNameApi(data).then((res)=>{
            setSubSubCategories(res.data)
        }).catch((err)=>{
            console.log(err)
        })
    }

    return (
        <HomeLayout>
            <div className={Style.BrowseTop}>
                <div className="d-flex align-items-center justify-content-between" style={{margin: "20px"}}>
                    <h1>{unDasher(pageTitle)}</h1>
                    {category ? <div className={Style.BrowseBack} onClick={()=>Navigate(-1)}>
                        <span>بازگشت</span>
                        <i style={{fontSize: "18px"}} className="las la-reply"></i>
                    </div> : null}
                </div>
                <Categories
                    province={province}
                    category={category}
                    subcat={subcat}
                    subsubcat={subsubcat}
                    categories={categories}
                    subCategories={subCategories}
                    subSubCategories={subSubCategories}
                />
                <Filter
                    categories={categories}
                    subCategories={subCategories}
                    subSubCategories={subSubCategories}
                    category={category}
                    subcat={subcat}
                    subsubcat={subsubcat}
                />

            </div>
            {loader ?
                <div
                    className="d-flex align-items-center justify-content-center"
                    style={{margin: "10px 0"}}
                >
                    <Spin/>
                </div>
                :
                ads?.data?.length ?
                    <ResponsiveMasonry
                        style={{margin: "0 15px"}}
                        columnsCountBreakPoints={{350: 2}}
                    >
                        <Masonry
                            gutter="10px"
                        >
                            {ads?.data?.map((Ad, Index)=>(
                                <Ads
                                    key={Index}
                                    Ad={Ad}
                                />
                            ))}
                        </Masonry>
                    </ResponsiveMasonry> :
                    <div className={Style.NoAds}>
                        <Alert message="موردی یافت نشد!" type="warning" />
                    </div>
            }

            <Loading status={loading}/>
        </HomeLayout>
    )
}

export default BrowseAds;